import { node, oneOf, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { InternalExternalLink } from '@/components/Link/InternalExternalLink';
import { getClient } from '@/lib/sanity';

const resolveSlugUrl = (host, slug) => `${host}${slug}`;

const resolveDataUrl = (data, subRoutePath = null, developmentSlug = null) => {
  const { slug, type } = data;

  switch (type) {
    case 'route':
    case 'corporateRoute':
      return resolveSlugUrl(
        process.env.NEXT_PUBLIC_CORPORATE_DOMAIN,
        `${slug}${subRoutePath ? `/${subRoutePath}` : ''}`,
      );
    case 'article':
    case 'corporateArticle':
      return resolveSlugUrl(
        process.env.NEXT_PUBLIC_CORPORATE_DOMAIN,
        `news/${slug}`,
      );
    case 'development':
      return resolveSlugUrl(
        process.env.NEXT_PUBLIC_CORPORATE_DOMAIN,
        `developments/${slug}`,
      );
    case 'property':
      return (
        developmentSlug &&
        resolveSlugUrl(
          process.env.NEXT_PUBLIC_CONSUMER_DOMAIN,
          `developments/${developmentSlug}/${slug}`,
        )
      );

    default:
      return null;
  }
};

const RouteClickAction = ({ action, children }) => {
  const { route: { routeReference, subRoutePath, openIn } = {} } = action;
  const routeId = routeReference?._ref ?? '';

  const [routeData, setRouteData] = useState(null);
  useEffect(() => {
    getClient(false)
      .fetch(`*[_id == $id][0] { 'type': _type, 'slug': slug.current }`, {
        id: routeId,
      })
      .then((res) => setRouteData(res));
  }, [routeId]);

  // Fetch development slug if route points to property
  const [developmentSlug, setDevelopmentSlug] = useState(null);
  useEffect(() => {
    if (routeData?.type !== 'property') setDevelopmentSlug(null);
    else
      getClient(false)
        .fetch(`*[_type == 'development' && references($propId)][0].slug.current`, {
          propId: routeId,
        })
        .then((res) => setDevelopmentSlug(res));
  }, [routeId, routeData]);

  const url = routeData && resolveDataUrl(routeData, subRoutePath, developmentSlug);

  const extra =
    openIn === 'new'
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};
  return (
    <>
      {url ? (
        <InternalExternalLink link={url} {...extra}>
          {children}
        </InternalExternalLink>
      ) : (
        <div>{children}</div>
      )}
    </>
  );
};

RouteClickAction.propTypes = {
  action: shape({
    route: shape({
      routeReference: shape({ _ref: string }),
      subRoutePath: string,
      openIn: oneOf(['same', 'new']),
    }),
  }),
  children: node,
};

RouteClickAction.defaultProps = {
  action: {
    route: {
      routeReference: { _ref: '' },
      subRoutePath: null,
      openIn: 'same',
    },
  },
  children: [],
};

export default RouteClickAction;
