import { node, shape, string } from 'prop-types';
import React from 'react';

import DownloadClickAction from '@/components/ClickAction/components/DownloadClickAction';
import ModalClickAction from '@/components/ClickAction/components/ModalClickAction';
import RegisterInterestClickAction from '@/components/ClickAction/components/RegisterInterestClickAction';
import RouteClickAction from '@/components/ClickAction/components/RouteClickAction';
import UrlClickAction from '@/components/ClickAction/components/UrlClickAction';
import { InternalExternalLink } from '@/components/Link/InternalExternalLink';

const TYPES = {
  ROUTE: 'route',
  URL: 'url',
  DOWNLOAD: 'download',
  MODAL: 'modal',
  REGISTER_INTEREST: 'registerInterest',
  LINK: 'link',
};

const resolveComponent = (type) => {
  if (type === TYPES.ROUTE) return RouteClickAction; // TODO: deprecate these later
  if (type === TYPES.URL) return UrlClickAction;
  if (type === TYPES.DOWNLOAD) return DownloadClickAction;
  if (type === TYPES.MODAL) return ModalClickAction;
  if (type === TYPES.REGISTER_INTEREST) return RegisterInterestClickAction;

  // eslint-disable-next-line react/prop-types
  return function ResolvedComponent({ children }) {
    <div>{children}</div>;
  };
};

const ClickAction = (props) => {
  const { children, action, section } = props;

  if (action.type === TYPES.LINK)
    return (
      <InternalExternalLink link={action.link}>{children}</InternalExternalLink>
    );

  const Component = resolveComponent(action.type);

  return (
    <Component action={action} section={section}>
      {children}
    </Component>
  );
};

ClickAction.propTypes = {
  action: shape({ type: string }),
  children: node,
  section: string,
};

ClickAction.defaultProps = {
  action: { type: '' },
  children: [],
  section: '',
};

export default ClickAction;
export { TYPES };
