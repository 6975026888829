import classnames from 'classnames';
import { func, shape, string, bool, node } from 'prop-types';
import React from 'react';

import RegisterInterestClickAction from '@/components/ClickAction/components/RegisterInterestClickAction';
import LegacyLinkClickAction from '@/components/ClickAction/legacy/LegacyUrlClickAction';
import Portal from '@/components/Portal/Portal';
import PrimaryButton, { VARIANTS } from '@/components/PrimaryButton';

import IconClose, { COLOUR2 } from '../assets/IconClose/IconClose';

import styles from './Modal.module.scss';

export default function Modal({
  handleClick,
  children,
  data,
  useVideoBox,
  actionData,
}) {
  const { lightboxTitle, lightboxSubtitle, lightboxContact, lightboxButtonName } =
    data;
  const showHeader = lightboxTitle || lightboxSubtitle;
  const showButton = lightboxContact;
  const wrapperClasses = classnames(styles.containerModal, {
    [styles.withHeader]: showHeader,
  });

  return (
    <Portal>
      <div className={wrapperClasses}>
        <div className={styles.container}>
          <div onClick={handleClick} className={styles.icon}>
            <IconClose color={COLOUR2.WHITE} />
          </div>
          {showHeader && (
            <div className={styles.header}>
              {lightboxTitle && <div className={styles.title}>{lightboxTitle}</div>}
              {lightboxSubtitle && (
                <div className={styles.subtitle}>{lightboxSubtitle}</div>
              )}
            </div>
          )}
          <div className={styles.content}>
            <div className={useVideoBox ? styles.box : ''}>{children}</div>
          </div>
          {showButton && (
            <div className={styles.buttonWrap} onClick={(e) => e.stopPropagation()}>
              {!actionData?.type && (
                <RegisterInterestClickAction
                  action={{ registerInterest: actionData }}
                >
                  <PrimaryButton as="button" variant={VARIANTS.TRANSPARENT}>
                    {lightboxButtonName ?? 'Contact Agent'}
                  </PrimaryButton>
                </RegisterInterestClickAction>
              )}
              {actionData?.type && (
                <LegacyLinkClickAction action={actionData} section="Lightbox">
                  <PrimaryButton as="button" variant={VARIANTS.TRANSPARENT}>
                    {lightboxButtonName ?? 'Contact Agent'}
                  </PrimaryButton>
                </LegacyLinkClickAction>
              )}
            </div>
          )}
        </div>
      </div>
    </Portal>
  );
}

Modal.propTypes = {
  handleClick: func,
  data: shape({
    lightboxTitle: string,
    lightboxSubtitle: string,
    lightboxContact: bool,
  }),
  children: node,
  onContactClick: func,
  useVideoBox: bool,
};

Modal.defaultProps = {
  handleClick: () => {},
  data: {
    lightboxTitle: '',
    lightboxSubtitle: '',
    lightboxContact: false,
  },
  children: null,
  onContactClick: () => {},
  useVideoBox: false,
};
