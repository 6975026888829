import classnames from 'classnames';
import { node, oneOf, string, func } from 'prop-types';
import React from 'react';

import IconArrow, { COLOUR } from '@/components/assets/IconArrow/IconArrow';

import { BUTTON_VARIANTS } from './PrimaryButtonOptions';

import styles from './PrimaryButton.module.scss';

export const VARIANTS = BUTTON_VARIANTS;

const PrimaryButton = ({ type, variant, children, onClick, as = 'span' }) => {
  const Element = as;
  const elementProps = {};
  if (as === 'button') elementProps.type = type || 'button';

  const buttonClasses = classnames(styles.button, styles[variant]);

  const iconColor =
    variant === BUTTON_VARIANTS.LIGHT || variant === BUTTON_VARIANTS.TRANSPARENT_DARK
      ? COLOUR.BLUE
      : COLOUR.WHITE;

  return (
    <Element className={buttonClasses} onClick={onClick} {...elementProps}>
      <span className={styles.text}>{children}</span>
      <div className={styles.icon}>
        <IconArrow color={iconColor} />
      </div>
    </Element>
  );
};

PrimaryButton.propTypes = {
  variant: oneOf(Object.values(BUTTON_VARIANTS)),
  children: node,
  type: string,
  onClick: func,
};

PrimaryButton.defaultProps = {
  variant: BUTTON_VARIANTS.DARK,
  children: '',
  type: 'button',
  onClick: () => {},
};

export default PrimaryButton;
