import getYouTubeId from 'get-youtube-id';
import { useRouter } from 'next/router';
import { node, oneOf, shape, string } from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import YouTube from 'react-youtube';

import Modal from '@/components/Modal/Modal';
import {
  scheduleAction,
  registerInterestAction,
  requestCallbackAction,
  virtualTourAction,
  downloadsAction,
  videoAction,
} from '@/helpers/gtmActions/gtmAcions';

import RegisterInterestClickAction from '../RegisterInterestClickAction';

import styles from './ModalClickAction.module.scss';

const ModalClickAction = ({ action, section, children }) => {
  const {
    modal: {
      type = 'iframe',
      url = '',
      lightboxContact = false,
      lightboxButtonName,
      lightboxClickAction,
      data = { title: '', subtitle: '' },
    } = {},
  } = action;

  const router = useRouter();
  const path = router.asPath;

  const iframeRef = useRef(null);

  const [isShowingModal, setIsShowingModal] = useState(false);
  const toggleModal = () => {
    setIsShowingModal(!isShowingModal);
  };

  const onBlur = () => {
    if (document.activeElement === iframeRef.current) {
      virtualTourAction('Self-tour', section, path);
    }
  };

  useEffect(() => {
    window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  });

  const handlePlay = (e) => {
    const progress = (e.target.getCurrentTime() / e.target.getDuration()) * 100;
    const duration = e.target.getDuration();
    const name = e.target.getVideoData().title;
    const siteType =
      process.env.NEXT_PUBLIC_SCOPE === 'corporate' ? 'Investors' : 'Public';

    videoAction(siteType, progress.toFixed(2), router.asPath, name, duration);
  };

  return (
    <div onClick={toggleModal} className={styles.wrapper}>
      {isShowingModal && (
        <Modal
          handleClick={toggleModal}
          data={{
            lightboxTitle: data.title,
            lightboxSubtitle: data.subtitle,
            lightboxContact,
            lightboxButtonName,
          }}
          actionData={lightboxClickAction}
          useVideoBox
        >
          {type === 'iframe' && (
            <iframe id="iframe" ref={iframeRef} src={url} title={data.title} />
          )}
          {type === 'video' && (
            <YouTube
              className={styles.youtube}
              opts={{
                playerVars: {
                  autoplay: 1,
                  disablekb: 0,
                  controls: 1,
                  loop: 1,
                  playsinline: 1,
                  showinfo: 0,
                  modestbranding: 0,
                  rel: 0,
                },
              }}
              videoId={getYouTubeId(url)}
              onStateChange={handlePlay}
            />
          )}
        </Modal>
      )}
      {children}
    </div>
  );
};

ModalClickAction.propTypes = {
  action: shape({
    type: oneOf(['iframe', 'video']),
    url: string,
    data: shape({
      title: string,
      subtitle: string,
    }),
  }),
  children: node,
  section: string,
};

ModalClickAction.defaultProps = {
  action: {
    type: 'iframe',
    url: '',
    data: {
      title: '',
      subtitle: '',
    },
  },
  children: [],
  section: '',
};

export default ModalClickAction;
