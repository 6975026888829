import { node, shape, string } from 'prop-types';

import ClickAction from '@/components/ClickAction';
import { InternalExternalLink } from '@/components/Link/InternalExternalLink';

const LegacyLinkClickAction = ({ action, url, section, children }) => {
  if (!action?.type && url) {
    return <InternalExternalLink link={url}>{children}</InternalExternalLink>;
  }

  if (action?.type) {
    return (
      <ClickAction action={action} section={section}>
        {children}
      </ClickAction>
    );
  }

  return <>{children}</>;
};

LegacyLinkClickAction.propTypes = {
  action: shape({ type: string }),
  url: string,
  children: node,
  section: string,
};

LegacyLinkClickAction.defaultProps = {
  action: null,
  url: '',
  children: [],
  section: '',
};

export default LegacyLinkClickAction;
