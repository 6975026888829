import classnames from 'classnames';
import React from 'react';

import styles from './IconArrow.module.scss';

export const COLOUR = {
  WHITE: '#ffffff',
  BLUE: '#072241',
  LIGHT_BLUE: '#004D69',
  WHITE_OPACITY_07: 'rgba(255, 255, 255, 0.7)',
  WHITE_OPACITY_1: 'rgba(255, 255, 255, 1)',
};
const IconArrow = ({ color, size }) => {
  const styleSize = classnames(styles[`${size}`]);
  return (
    <svg
      className={styleSize}
      width="13"
      height="18"
      viewBox="0 0 13 18"
      fill="none"
    >
      <path
        d="M1.5 1L11.5 9.15094L1.87037 17"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconArrow;
