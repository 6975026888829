import { useRouter } from 'next/router';
import { node, oneOf, shape, string } from 'prop-types';
import React from 'react';

import { outboundLinkAction } from '@/helpers/gtmActions/gtmAcions';

const UrlClickAction = ({ action, children }) => {
  const { url: { url, openIn = 'new' } = {} } = action;
  const router = useRouter();

  const extra =
    openIn === 'new'
      ? {
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      : {};

  const path = router.asPath;

  const handleClick = () => {
    outboundLinkAction(url, path);
  };

  return (
    <a {...extra} href={url ?? ''} onClick={handleClick} className="urlClickAction">
      {children}
    </a>
  );
};

UrlClickAction.propTypes = {
  action: shape({
    url: shape({
      url: string,
    }),
    openIn: oneOf(['same', 'new']),
  }),
  children: node,
};

UrlClickAction.defaultProps = {
  action: {
    url: { url: '' },
    openIn: 'new',
  },
  children: [],
};

export default UrlClickAction;
