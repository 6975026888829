import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { node, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Suspense } from 'react';

import Portal from '@/components/Portal/Portal';
import { registerInterestAction } from '@/helpers/gtmActions/gtmAcions';
import { getClient } from '@/lib/sanity';

import styles from './RegisterInterestClickAction.module.scss';

const RegisterInterest = dynamic(() => import('@/components/RegisterInterest'), {
  suspense: true,
});

const RegisterInterestClickAction = ({ action, children }) => {
  const {
    registerInterest: { developmentReference, propertyReference, pardotUrl } = {},
  } = action;
  const developmentId = developmentReference?._ref ?? '';
  const propertyId = propertyReference?._ref ?? '';

  const [isRegisterFlowOpen, setIsRegisterFlowOpen] = useState(false);
  const [developmentData, setDevelopmentData] = useState(null);

  const router = useRouter();

  useEffect(() => {
    getClient(false)
      .fetch(
        `*[_type == 'development' && _id == $id][0] { ..., 'property': *[_type == 'property' && _id == $propId][0]{title}, properties[]-> }`,
        {
          id: developmentId,
          propId: propertyId,
        },
      )
      .then((res) => setDevelopmentData(res));
  }, [developmentId, propertyId]);

  const handleRegisterFlowOpen = (e) => {
    e.stopPropagation();

    registerInterestAction('Hero', router.asPath);
    setIsRegisterFlowOpen(true);
  };
  const handleRegisterFlowClose = (e) => {
    e.stopPropagation();
    setIsRegisterFlowOpen(false);
  };

  return (
    <div onClick={handleRegisterFlowOpen} className={styles.wrapper}>
      {developmentData && isRegisterFlowOpen && (
        <Portal>
          <Suspense fallback={`loading…`}>
            <RegisterInterest
              developmentData={developmentData}
              onCloseClick={handleRegisterFlowClose}
              pardotUrl={pardotUrl}
            />
          </Suspense>
        </Portal>
      )}
      {children}
    </div>
  );
};

RegisterInterestClickAction.propTypes = {
  action: shape({
    registerInterest: shape({
      pardotUrl: string,
      developmentReference: shape({ _ref: string }),
    }),
  }),
  children: node,
};

RegisterInterestClickAction.defaultProps = {
  action: {
    registerInterest: {
      pardotUrl: '',
      developmentReference: { _ref: '' },
    },
  },
  children: [],
};

export default RegisterInterestClickAction;
