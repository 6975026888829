import React from 'react';

import styles from './IconClose.module.scss';

export const COLOUR2 = {
  BLUE: '#072241',
  WHITE: '#ffffff',
};
const IconClose = ({ color }) => {
  return (
    <svg
      className={styles.size}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.832517 15.4673C0.497782 15.1326 0.497782 14.5898 0.832517 14.2551L13.7819 1.30574C14.1166 0.971003 14.6593 0.971003 14.9941 1.30574C15.3288 1.64047 15.3288 2.18319 14.9941 2.51792L2.0447 15.4673C1.70997 15.802 1.16725 15.802 0.832517 15.4673Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.947894 1.19236C1.28263 0.85763 1.82534 0.85763 2.16008 1.19236L15.1102 14.1425C15.445 14.4773 15.445 15.02 15.1102 15.3547C14.7755 15.6894 14.2328 15.6894 13.898 15.3547L0.947895 2.40455C0.61316 2.06981 0.613159 1.5271 0.947894 1.19236Z"
        fill={color}
      />
    </svg>
  );
};

export default IconClose;

// export const IconCloseMenu = () => {
//   return (
//     <svg
//       width="22"
//       height="22"
//       viewBox="0 0 22 22"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M1.14466 20.58C0.684394 20.1197 0.684394 19.3735 1.14466 18.9132L18.9501 1.10781C19.4103 0.647548 20.1565 0.647549 20.6168 1.10781C21.0771 1.56807 21.0771 2.3143 20.6168 2.77456L2.81141 20.58C2.35115 21.0402 1.60492 21.0402 1.14466 20.58Z"
//         fill="#072241"
//       />
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M1.30334 0.952028C1.7636 0.491767 2.50983 0.491767 2.97009 0.952028L20.7766 18.7585C21.2368 19.2188 21.2368 19.965 20.7766 20.4252C20.3163 20.8855 19.5701 20.8855 19.1098 20.4252L1.30334 2.61878C0.843081 2.15852 0.843082 1.41229 1.30334 0.952028Z"
//         fill="#072241"
//       />
//     </svg>
//   );
// };
