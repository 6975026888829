import { useRouter } from 'next/router';
import { node, shape, string } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';

import { InternalExternalLink } from '@/components/Link/InternalExternalLink';
import { downloadsAction } from '@/helpers/gtmActions/gtmAcions';
import { getClient } from '@/lib/sanity';

import { SiteContext } from '../../../../context';
import resolveCustomFileDownloadUrl from '../../../../studio/src/resolveUrl/resolveCustomFileDownloadUrl';

const DownloadClickAction = ({ action, children }) => {
  const {
    config: { url: host },
  } = useContext(SiteContext);
  const { download: { fileReference } = {} } = action;
  const fileId = fileReference?._ref ?? '';
  const router = useRouter();

  const path = router.asPath;

  const [fileData, setFileData] = useState(null);
  useEffect(() => {
    getClient(false)
      .fetch(`*[_id == $id][0] { 'slug': slug.current }`, { id: fileId })
      .then((res) => setFileData(res));
  }, [fileId]);
  const url = fileData && resolveCustomFileDownloadUrl(host, fileData.slug);

  const handleClick = () => {
    const siteType =
      process.env.NEXT_PUBLIC_SCOPE === 'corporate' ? 'Investors' : 'Public';
    downloadsAction(siteType, path, url);
  };

  return (
    <InternalExternalLink href={url ?? ''} download onClick={handleClick}>
      {children}
    </InternalExternalLink>
  );
};

DownloadClickAction.propTypes = {
  action: shape({
    fileReference: shape({ _ref: string }),
  }),
  children: node,
};

DownloadClickAction.defaultProps = {
  action: {
    fileReference: { _ref: '' },
  },
  children: [],
};

export default DownloadClickAction;
