import { createClient, createPreviewSubscriptionHook } from 'next-sanity';

export const config = {
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  useCdn: process.env.NODE_ENV === 'production',
  apiVersion: '2021-03-25',
};

if (!config.projectId) {
  throw Error('The Project ID is not set. Check your environment variables.');
}
if (!config.dataset) {
  throw Error('The dataset name is not set. Check your environment variables.');
}

// Set up the live preview subsscription hook
export const usePreviewSubscription = createPreviewSubscriptionHook({
  ...config,
  documentLimit: 10000,
});

// Set up the client for fetching data in the getProps page functions
export const sanityClient = createClient(config);
// Set up a preview client with serverless authentication for drafts
export const previewClient = createClient({
  ...config,
  useCdn: false,
});

// Helper function for easily switching between normal client and preview client
export const getClient = (usePreview) => (usePreview ? previewClient : sanityClient);

export const createLivePreviewFrontendClient = (previewToken) => {
  if (!previewToken) return null;
  return createClient({
    ...config,
    useCdn: false,
    token: previewToken,
    ignoreBrowserTokenWarning: true,
  });
};
